import $ from 'jquery'

export default function init () {
  $(document).on('click', 'a[data-scroll]', function (e) {
    e.preventDefault()
    let scrollTarget = $(this).attr('href')
    console.log(scrollTarget)

    $('html, body').animate({
      scrollTop: $(scrollTarget).offset().top
    }, 600, 'swing')
  })
}
